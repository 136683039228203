<template>
    <v-form>
        <v-subheader><v-icon class="iconForm">mdi-information</v-icon>Informations</v-subheader>
        <v-text-field outlined v-model="bar.name" placeholder="Nom du bar"/>
        <v-textarea outlined v-model="bar.description.fr" placeholder="Description (Français)"/>
        <v-textarea outlined v-model="bar.description.en" placeholder="Description (Anglais)"/>
        <v-subheader><v-icon class="iconForm">mdi-map-marker</v-icon>Localisation</v-subheader>
    </v-form>
</template>
<script>
export default {
    data: () => ({
        bar: {name: '', description: {fr: '', en: ''}, photos: []}
    })
}
</script>