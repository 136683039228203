<template>
  <v-dialog fullscreen v-model="dialog">
    <v-card>
      <v-card-title>
      
        <h2>A propos</h2>
        <v-spacer />
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-subheader>Informations</v-subheader>
        <p>Cette application est éditée par la société SAS SBD - 89136769000015 RCS Bourg en Bresse </p>
        <p>La volonté est de mettre en place l'ouverture des terasses et de fournir un outil permettant de trouver une pinte à proximité de soit (et différents fonctionnalités afférentes)</p>
        <v-subheader>Hébergement</v-subheader>
        <p>
          Cette application est hébergée sur un hébergement AWS Europe de type S3 et sur un CDN Cloudfront. La zone utilisé est "EU-WEST-3 (Paris)".
          Les emails sont sauvegardés dans une base Firebase Firestore de Google Cloud Platform (zone "eu-3")
        </p>
        <v-subheader>Données</v-subheader>
        <p>La protection de vos données est primordiales pour nous: une remarque, une question, un problème ? Contactez-nous:  </p>
        <p>Les données susceptibles d'être enregistrées en base de données sont l'email et l'heure de soumission du formulaire. Ces données sont soumises lorsque l'utilisateur valide le formulaire "contact".
        Cet email ne sera utilisé qu'aux fins annoncées, c'est à dire informer les utilisateurs l'ayant soumis de l'ouverture du formulaire "bar" et de la carte et des fonctions afférentes de ViteMaPinte.App</p>
        <p>Une mesure d'audience utilisant Google Analytics est aussi en place et désactivée par défaut.
          
         <!-- <v-btn text @click="enable();" v-if="storage.getItem('ga-disabled') == true">Activer</v-btn>
          <v-btn text @click="disable();" v-else>Désactiver</v-btn>--> </p>
        <p>Vous bénéficez d'un droit d'accès, de modification, de suppression de ces données en contactant: <a target="_blank" href="mailto:vitemapinte@gmail.com">ViteMaPinte@gmail.com</a> </p>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['close', 'dialog'],
  methods: {
    enable() {
      window.localStorage.setItem('ga-disabled', false);
      this.reload();
    },
    disable() {
      window.localStorage.setItem('ga-disabled', true);
      this.reload();
    },
    reload: function() {
      window.location.reload();
    }
  },
  computed: {

    storage: function() {
      return window.localStorage;
    }
  }
}
</script>
