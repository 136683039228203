<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
     <span class="emoji"> 🍺 </span>
       <v-toolbar-title class="headline text-uppercase">
        
    <span >Vite</span>
    <span class="font-weight-light">Ma</span>
    <span >Pinte</span>
  </v-toolbar-title>
    </v-app-bar>

    <v-main>
<router-view></router-view>
    
    </v-main>
     <v-footer  class="footer">
       <span class="mr-3">2021 - SBD</span> &bullet;
       <a class="ml-3" @click="about = true;">A propos</a>
     </v-footer>
<About :dialog="about" :close="() => { this.about = false; }" />
  <v-snackbar :vertical="true"  :multi-line="true" v-model="snackbar" :timeout="-1">
    Ce site effectue une mesure d'audience anonyme que vous pouvez refuser ou accepter. Merci :)
<template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false" 
        >
          Refuser
        </v-btn>
        <v-spacer />
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="accept"
        >
          Accepter
        </v-btn>
      </template>
  </v-snackbar>
  </v-app>
</template>

<script>

import About from './views/About';
export default {
  name: 'App',
  components: { About },
  methods: {
    accept() {
      localStorage.setItem('ga-disabled', false);

      window.location.reload();
    }
  },
  data: () => ({
    about: false,
    snackbar: localStorage.getItem('ga-disabled') == null
  }),
};
</script>
<style scoped>
.footer {
  justify-content: center;
}
.emoji {
  font-size: 30px;
  margin-right: 10px;
}
</style>
