<template>
    <v-container>
       
        <h2>Ajouter mon établissement</h2>
        <v-text-field id="autocomplete" v-model="autocomplete2"  outlined placeholder="Saisissez le nom de votre établissement">
        </v-text-field>
        <form-bar />
    </v-container>
</template>

<script>
import gmapsInit from '../../utils/gmaps';
import form from './form';
export default {
    data:() => ({
        key: 'AIzaSyDB2ckUQirEqLc3uKWD4S-krxBGdB1HFdc',
        items: [],
        autocomplete2: null,
        autocomplete: null,
        search: null
    }),
    components: {'form-bar': form},
    async mounted() {
        fetch('https://maps.googleapis.com/maps/api/place/js/PhotoService.GetPhoto?1sATtYBwLBQpkRC2X_CJQGAB9AU0g8JFsWV-IiUmDNXdGo09D5cfrlPWahX44cBtH89UC4bjoum98kNgLPzWYkxQW1_gNWv-WluKKANGYRDa8g4NmCKlQKvwirGHYbNYLahMbvc2pBmXZg-d5UgI3kAUcOB7CD50-si5Ar7WCgiMT1iUUuzbik&3u5526&5m1&2e1&callback=none&key=AIzaSyDB2ckUQirEqLc3uKWD4S-krxBGdB1HFdc&token=67846');
         try {
                const google = await gmapsInit();
                this.autocomplete = new google.maps.places.Autocomplete(
                  /** @type {HTMLInputElement} */(document.getElementById('autocomplete')),
                  { componentRestrictions: { country: "fr" },types: ['establishment'] });
                  const self = this;
              google.maps.event.addListener(this.autocomplete, 'place_changed', function() {
                  
                  let place = self.autocomplete.getPlace();
                    console.log(place.photos.map((p) => p.getUrl())[0]);
              });
         }  catch (error) {
            console.error(error);
        }
    },
    methods: {
       
    },
    watch: {
        'autocomplete': async function(v){
            console.log(v);
            /*let data =  await v.getPlace();
            console.log(data);*/
        }
    }
    
}
</script>