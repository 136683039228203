<template>
    <v-card class="rounded-lg">
        <v-card-title class="nowrap">Prochainement vous pourrez retrouver les pintes les plus proches sur cet app.</v-card-title>
        <v-card-subtitle>Laissez votre email afin d'être informé du lancement de  l'app!</v-card-subtitle>
        <v-card-text>
          <FormContact type="user" />
        </v-card-text>
    </v-card>
</template>
<script>
import FormContact from './formContact';

export default {
    components: { FormContact }
    
}
</script>