<template>
    <v-card  class="rounded-lg">
        <v-card-title><v-icon class="mr-3">mdi-glass-mug-variant</v-icon>Proriétaire d'un bar</v-card-title>
         <v-divider />
        <v-card-subtitle>Laissez votre email afin d'être averti du lancement de l'espace "ajout" de bar.</v-card-subtitle>
       
        <v-card-text>
              <FormContact type="bar" />
        </v-card-text>
    </v-card>
</template>
<script>
import FormContact from './formContact';
export default {
    components : { FormContact}
}
</script>