<template>
    <v-card class="rounded-lg">
           <v-card-title><v-icon class="mr-4"></v-icon>Les terrasses ouvrent dans:</v-card-title>
           <v-card-text class="text-center font-weight-bold text-h4">
            {{updatedDate/1000 | timestampToString }}
           </v-card-text>
    </v-card>
</template>
<script>
export default {
    filters: {
        timestampToString(timestamp, useMilliseconds) {
  const periods = {
    jour: 86400000,
    heure: 3600000,
    minute: 60000,
    seconde: 1000,
    millisecond: 1,
  };

  if (!useMilliseconds) {
    timestamp *= 1000;
    delete periods.millisecond;
  }

  return Object.entries(periods)
    .reduce((result, [period, value]) => {
      const num = Math.floor(timestamp / value);
      const plural = num === 1 ? '' : 's';
      const str = `${num} ${period}${plural} `;

      timestamp -= num * value;

      return `${result}${num === 0 ? '' : str}`;
    }, '')
    .trim();
}
    },
    data:() => ({
        date: new Date('2021-05-19'),
        updatedDate: null
    }),
    mounted() {
        setInterval(() => {
            this.updatedDate = this.date - new Date();
        }, 1000);
    }
}
</script>