<template>
  <v-container>
    <CardTimer class="mb-5" />
    <CardContactUser class="mb-5" />
  <CardContactBar class="mb-5" />
  <CardResponsible class="mb-5" />
  </v-container>
</template>

<script>
import CardTimer from './home/cardTimer';
import CardContactUser from './home/cardContactUser';
import CardContactBar from './home/cardContactBar';
import CardResponsible from './home/cardResponsible';
  export default {
    name: 'Home',

    components: {
      CardTimer, CardContactBar, CardResponsible, CardContactUser
    },
  }
</script>
