<template>
<div>
    <v-form @submit.prevent="submit" v-if="!send">
        <v-text-field  :style="{'align-items': 'baseline'}" type="email" placeholder="Votre email" dense outlined v-model="form.email" >
            <template v-slot:append-outer>
    <v-btn
    type="submit"
  
      :loading="loading"
     :disabled="!formValid"
    >
     OK
    </v-btn>
  </template>
        </v-text-field>
    </v-form>
    <v-sheet v-else>
Merci e-mail enregistré  !
        </v-sheet>
    </div>
</template>
<script>
import firebase from '@firebase/app';
import   '@firebase/firestore';
export default {
    mounted() {
        this.form.type = this.type;
    },
    props: ['type'],
    data: () => ({
        loading: false,
        send: false,
        form: {email: '', type: ''},
        regEmail: /^(([^<>()[\].,;:@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    }),
    methods: {
        submit() {
            this.loading = true;
             let db  = firebase.firestore();
             this.form.time = firebase.firestore.FieldValue.serverTimestamp();

        db.collection('contact').doc().set(this.form).then(() => {
            this.form.email = '';
            this.send = true;
        }).finally(() => {
            this.loading = false;
        })
            
        }
    },
    computed: {
        formValid: function() {
            return this.regEmail.test(this.form.email);
        }
    }
}
</script>