import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import firebase from '@firebase/app';
import "@firebase/analytics";
Vue.config.productionTip = false

var firebaseConfig = {
  apiKey: "AIzaSyAPLLjvIhBbCdTaLBY05n-DJAoTrvOpfMs",
  authDomain: "vitemapinte.firebaseapp.com",
  projectId: "vitemapinte",
  storageBucket: "vitemapinte.appspot.com",
  messagingSenderId: "184572995318",
  appId: "1:184572995318:web:cb7b9002d4c92faf38aed7",
  measurementId: null
};
// Initialize Firebase


firebase.initializeApp(firebaseConfig);

firebase.analytics();
window['ga-disable-G-NYM40Y7DY2'] = localStorage.getItem('ga-disabled')  || true;


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
